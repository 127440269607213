'use client';

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useMediaQuery } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import { cn } from '@app/components/lib/utils';

import CustomTooltip from './CustomTooltip';

export interface ILineChartData {
    name: string;
    amt: number;
    xaxisTick?: string;
    toolTipFooter?: React.ReactNode;
}

export default function CustomLineChart({
    chartData,
    className,
    strokeColor = '#1C63E7',
    strokeCoverColor = '#0093FD1A',
    fillGradiant = true,
    strokeWidth = '5',
    renderLines = true,
    renderDot = false,
    renderToolTip = true,
    renderXaxis = true,
    renderYaxis = true,
    smoothStroke = true,
    xaxisInterval = 0,
    showOnlyTransaction = false,
    positionYToolTip,
    positionXToolTip
}: {
    chartData?: ILineChartData[];
    className?: string;
    strokeColor?: string;
    strokeCoverColor?: string;
    fillGradiant?: boolean;
    strokeWidth?: string;
    renderLines?: boolean;
    renderDot?: boolean;
    renderToolTip?: boolean;
    renderXaxis?: boolean;
    renderYaxis?: boolean;
    smoothStroke?: boolean;
    xaxisInterval?: number;
    showOnlyTransaction?: boolean;
    positionYToolTip?: number;
    positionXToolTip?: number;
}) {
    const uniqueId = uuidv4(); // Generate a unique ID for this chart instance

    const isMobile = useMediaQuery('(max-width: 600px)');

    const getInterval = (x: number) => {
        if (x < 10) {
            return 1;
        }
        if (x < 30) {
            return 5;
        }
        return 10;
    };

    return (
        <ResponsiveContainer>
            <AreaChart data={chartData} className={cn(className, 'm-l-[-16] m-0 ml-[-16px] md:ml-0')}>
                <defs>
                    <linearGradient id={`colorUv-${uniqueId}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={strokeCoverColor} stopOpacity={1} />
                        <stop offset="100%" stopColor={strokeCoverColor} stopOpacity={0} />
                    </linearGradient>
                </defs>
                {renderLines && (
                    <CartesianGrid strokeDasharray="0" vertical={false} stroke="#A2A3A5" strokeOpacity={0.4} />
                )}
                {renderYaxis && (
                    <YAxis
                        dx={-10}
                        tickCount={4}
                        axisLine={false}
                        tickLine={false}
                        stroke="#A2A3A5"
                        allowDecimals={false}
                        className={'text-[10px] md:text-lg'}
                    />
                )}
                {renderToolTip && (
                    <Tooltip
                        content={<CustomTooltip showOnlyTransaction={showOnlyTransaction} />}
                        cursor={{ strokeDasharray: 5, stroke: '#1C63E7' }}
                        position={{ y: positionYToolTip, x: positionXToolTip }}
                    />
                )}
                <Area
                    type={smoothStroke ? 'monotone' : 'linear'}
                    dataKey="amt"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    fillOpacity={1}
                    fill={fillGradiant ? `url(#colorUv-${uniqueId})` : strokeCoverColor}
                    isAnimationActive={false}
                    strokeLinecap="round"
                    dot={renderDot}
                    activeDot={{ r: 8 }}
                />
                {renderXaxis && (
                    <XAxis
                        dataKey={'xaxisTick'}
                        tickLine={false}
                        dy={5}
                        dx={-25}
                        fill="#2196F3"
                        stroke="#A2A3A5"
                        interval={isMobile ? getInterval(chartData?.length || 1) : xaxisInterval}
                        reversed={true}
                        className={'text-[10px] xl:text-lg'}
                    ></XAxis>
                )}
            </AreaChart>
        </ResponsiveContainer>
    );
}
