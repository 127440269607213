import React from 'react';

export default function AgentsIcon(props: React.SVGAttributes<any>) {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.5 2.24988C14.1845 2.24988 14.7375 2.8783 14.7375 3.65613V6.46863H19.3781C20.9173 6.46863 22.1625 7.88367 22.1625 9.63269V21.5858C22.1625 23.3348 20.9173 24.7499 19.3781 24.7499H7.62188C6.08273 24.7499 4.8375 23.3348 4.8375 21.5858V9.63269C4.8375 7.88367 6.08273 6.46863 7.62188 6.46863H12.2625V3.65613C12.2625 2.8783 12.8155 2.24988 13.5 2.24988ZM11.3344 13.4999C11.3344 13.0337 11.1714 12.5866 10.8813 12.2569C10.5912 11.9273 10.1978 11.7421 9.7875 11.7421C9.37724 11.7421 8.98379 11.9273 8.6937 12.2569C8.4036 12.5866 8.24063 13.0337 8.24063 13.4999C8.24063 13.9661 8.4036 14.4132 8.6937 14.7428C8.98379 15.0725 9.37724 15.2577 9.7875 15.2577C10.1978 15.2577 10.5912 15.0725 10.8813 14.7428C11.1714 14.4132 11.3344 13.9661 11.3344 13.4999ZM17.2125 15.2577C17.6228 15.2577 18.0162 15.0725 18.3063 14.7428C18.5964 14.4132 18.7594 13.9661 18.7594 13.4999C18.7594 13.0337 18.5964 12.5866 18.3063 12.2569C18.0162 11.9273 17.6228 11.7421 17.2125 11.7421C16.8022 11.7421 16.4088 11.9273 16.1187 12.2569C15.8286 12.5866 15.6656 13.0337 15.6656 13.4999C15.6656 13.9661 15.8286 14.4132 16.1187 14.7428C16.4088 15.0725 16.8022 15.2577 17.2125 15.2577ZM2.98125 12.0936H3.6V20.5311H2.98125C1.95645 20.5311 1.125 19.5863 1.125 18.4218V14.203C1.125 13.0385 1.95645 12.0936 2.98125 12.0936ZM24.0188 12.0936C25.0436 12.0936 25.875 13.0385 25.875 14.203V18.4218C25.875 19.5863 25.0436 20.5311 24.0188 20.5311H23.4V12.0936H24.0188Z"
                fill="currentColor"
                fillOpacity="0.87"
            />
            <path d="M9 19.6876H18" stroke="#E3F2FD" strokeWidth="1.125" strokeLinecap="round" />
        </svg>
    );
}
